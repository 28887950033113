var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',_vm._l((_vm.cartItems),function(cart_item,i){return _c('v-list-item',{key:cart_item.cart_id,staticClass:"px-0 py-3",class:[
      { 'text-reset': cart_item.outOfStock },
      { 'border-top': i !== 0 }
    ]},[_c('div',{staticClass:"w-100"},[_c('div',{staticClass:"position-relative"},[(cart_item.outOfStock)?_c('v-chip',{staticClass:"absolute-top-left white--text z-1",attrs:{"color":"red","x-small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$t('out_of_stock'))+" ")]):_vm._e(),_c('div',{class:[
            'd-flex align-center',
            { 'opacity-50': cart_item.outOfStock }
          ]},[_c('v-checkbox',{staticClass:"mt-0 pt-0",attrs:{"on-icon":"la-check","hide-details":"","input-value":cart_item.selected,"disabled":cart_item.outOfStock},on:{"change":function($event){return _vm.toggleCartItem({ cart_id: cart_item.cart_id, status: $event })}}}),_c('div',{staticClass:"flex-shrink-0 lh-0"},[_c('img',{staticClass:"img-fluid size-70px",attrs:{"src":_vm.addResourceImage(cart_item.thumbnail),"alt":cart_item.name},on:{"error":function($event){return _vm.imageFallback($event)}}})]),_c('div',{staticClass:"flex-grow-1 minw-0 ms-3"},[_c('div',{staticClass:"text-truncate fs-12 opacity-80 mb-2"},[_vm._v(" "+_vm._s(cart_item.name)+" ")]),_c('div',{class:[
                'd-flex align-center',
                { 'pointer-disbled': cart_item.outOfStock }
              ]},[_c('v-btn',{staticClass:"rounded btn-xxs",attrs:{"color":"primary","elevation":"0","fab":"","x-small":""},on:{"click":function($event){return _vm.updateQuantity({
                    type: 'minus',
                    cart_id: cart_item.cart_id
                  })}}},[_c('i',{staticClass:"las la-minus"})]),_c('span',{staticClass:"mx-3 fs-12"},[_vm._v(_vm._s(cart_item.qty))]),_c('v-btn',{staticClass:"rounded btn-xxs",attrs:{"color":"primary","elevation":"0","fab":"","x-small":""},on:{"click":function($event){return _vm.updateQuantity({ type: 'plus', cart_id: cart_item.cart_id })}}},[_c('i',{staticClass:"las la-plus"})])],1)]),_c('div',{staticClass:"flex-shrink-0 w-80px text-end"},[(cart_item.regular_price > cart_item.dicounted_price)?_c('del',{staticClass:"opacity-50"},[_vm._v(" "+_vm._s(_vm.format_price(cart_item.regular_price * cart_item.qty))+" ")]):_vm._e(),_c('div',{staticClass:"text-red"},[_vm._v(" "+_vm._s(_vm.format_price(cart_item.dicounted_price * cart_item.qty))+" ")])]),_c('div',{staticClass:"ms-4"},[_c('button',{on:{"click":function($event){return _vm.removeFromCart(cart_item.cart_id)}}},[_c('i',{staticClass:"las la-trash fs-20 opacity-50"})])])],1)],1),(cart_item.combinations && cart_item.combinations.length > 0)?_c('div',{staticClass:"ms-5"},_vm._l((cart_item.combinations),function(combination,j){return _c('span',{key:j,staticClass:"px-4 py-1 fs-12"},[_c('span',{staticClass:"opacity-70"},[_vm._v(_vm._s(combination.attribute))]),_vm._v(" : "),_c('span',{staticClass:"fw-500"},[_vm._v(_vm._s(combination.value))])])}),0):_vm._e()])])}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }