<template>
  <v-list-item-group color="#FF9595">
    <v-list-item
      v-for="(route, i) in routes"
      :key="i"
      :to="{ name: route.to }"
      class="mb-0 text-primary"
    >
      <v-list-item-icon class="me-4 d-flex align-center">
        <i :class="route.icon" class="fs-17 text-primary"></i>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title
          class="fw-500 fs-14"
        >
        <span class="text-primary">{{ route.label }}</span>
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </v-list-item-group>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  computed: {
    ...mapGetters('app', [
      'generalSettings',
      'getUnseenProductQuerries',
      'getProductMessage',
      'showContern'
    ]),
    ...mapGetters('auth', ['currentUser']),
    routes() {
      return this.getMenuItems().sort((a, b) => a.order - b.order)
    }
  },

  created() {
    this.fetchShowConcern()
  },

  methods: {
    ...mapActions('app', ['fetchShowConcern']),

    getMenuItems() {
      const items = [
        {
          label: this.$i18n.t('dashboard'),
          order: 10,
          icon: 'las la-th-large',
          to: 'DashBoard'
        },
        {
          label: this.$i18n.t('purchase_history'),
          order: 20,
          icon: 'las la-file-invoice-dollar',
          to: 'PurchaseHistory'
        },
        {
          label: this.$i18n.t('wishlist'),
          order: 30,
          icon: 'la la-heart-o',
          to: 'Wishlist'
        },
        // {
        //   label: this.$i18n.t('coupons'),
        //   order: 50,
        //   icon: 'las la-tags',
        //   to: 'Coupon'
        // },
        {
          label: this.$i18n.t('manage_profile'),
          order: 60,
          icon: 'las la-user',
          to: 'Profile'
        }
      ]

      const wallet = {
        label: this.$i18n.t('my_wallet'),
        order: 40,
        icon: 'las la-wallet',
        to: 'Wallet'
      }
      if (this.generalSettings.wallet_system == 1) {
        items.push(wallet)
      }

      const refund_request = {
        label: this.$i18n.t('refund_requests'),
        order: 21,
        icon: 'las la-undo-alt',
        to: 'RefundRequests'
      }
      if (this.is_addon_activated('refund_request')) {
        items.push(refund_request)
      }

      // done: 隐藏店铺收藏
      // const shop = {
      //     label: this.$i18n.t('followed_shops'),
      //     order: 35,
      //     icon: 'las la-store-alt',
      //     to: 'FollowedShops',
      // }

      // if (this.is_addon_activated('multi_vendor')) {
      //     items.push(shop)
      // }

      // conversation
      const conversation = {
        label: `${this.$i18n.t('product_querries')}(${
          this.getProductMessage || 0
        })`,
        order: 36,
        icon: 'la la-comment',
        to: 'Conversations'
      }
      if (
        this.generalSettings.conversation_system == 1 &&
        this.is_addon_activated('multi_vendor')
      ) {
        items.push(conversation)
      }

      const myFocus = {
        label: `${this.$i18n.t('my_concern')}(${
          this.currentUser.follows || 0
        })`,
        order: 37,
        icon: 'la la-star-o',
        to: 'Focus'
      }
      if (this.showContern) {
        items.push(myFocus)
      }

      return items
    }
  }
}
</script>

<style scoped>
.user-side-nav .v-item--active:before {
  background-color: #FFF1F1 !important;
}
.user-side-nav .v-item--active {
  color: #FF9595;
}
</style>
